// extracted by mini-css-extract-plugin
export var header = "about-module--header--32S03";
export var slideInRight = "about-module--slideInRight--3fTYK";
export var header2 = "about-module--header2--3Xork";
export var youtubeblock = "about-module--youtubeblock--3UQdY";
export var youtubetitle = "about-module--youtubetitle--OMGmT";
export var youtube = "about-module--youtube--znBAO";
export var kaisetsublock = "about-module--kaisetsublock--2gKe1";
export var kaisetsucont = "about-module--kaisetsucont--1a0-c";
export var kaisetsu = "about-module--kaisetsu--3KyFL";
export var simage = "about-module--simage--23sE-";
export var footer = "about-module--footer--8IqcL";
export var container = "about-module--container--1FoEI";
export var movie = "about-module--movie--3-ho5";
export var moviebutton = "about-module--moviebutton--SR1D_";
export var moviebutton1 = "about-module--moviebutton1--_I6el";
export var keyvisual = "about-module--keyvisual--Jo4Cr";
export var tablecss = "about-module--tablecss--398HW";
export var main = "about-module--main--hGbj0";
export var sidebar = "about-module--sidebar--38ByD";
export var box11 = "about-module--box11--lo68y";
export var SlideIn = "about-module--SlideIn--EX0kr";
export var box12 = "about-module--box12--j-lTe";
export var box21 = "about-module--box21--3tKqR";
export var box22 = "about-module--box22--14_7Z";
export var box32 = "about-module--box32--18VS-";
export var box31 = "about-module--box31--2A5HF";