import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout4';
import SEO from '../components/seo';
import { tablecss, container, header, header2, youtubeblock, youtubetitle, youtube, kaisetsublock, kaisetsucont, kaisetsu, simage, movie, moviebutton, moviebutton1, main, sidebar, box11, box12, box21, box22, box31, box32, footer } from './about.module.css';
import { StaticImage } from "gatsby-plugin-image";

const App = () => {
  return (
    <Layout>
      <SEO
        title="ジオグラフの使い方"
        description="全国の市町村データを３Dで可視化！誰でも，簡単に無料で使えるWeb GISです。人口や産業に関する1kmメッシュデータを地図上に三次元表示し，地域にあらわれる現象や課題を視覚的に捉えることができます。地理総合や地理探究の学習項目や，SDGｓの17の開発目標からテーマを選択できます。"
      />

<div id="t"></div>


<div>
    {/* <div className={header}>
        <h1>ジオグラフかんたん解説動画</h1>
    </div>
    <div className={youtubeblock}>
        <div className={youtube}>
            <iframe width="1120" height="315" src="https://www.youtube.com/embed/UK90VaTUY6U?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
        </div>
        <div className={youtube}>
            <iframe width="1120" height="315" src="https://www.youtube.com/embed/pVnIi8QIFNg?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
        </div>
        <div className={youtube}>
            <iframe width="1120" height="315" src="https://www.youtube.com/embed/DUyZG2oeuWE?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
        </div>
    </div> */}

    <div className={header}>
        <h1>ジオグラフの使い方</h1>
    </div>

    <div className={kaisetsublock}>

    <div className={header2}>
        <h2 >0.ジオグラフかんたん解説動画</h2>
    </div>
    <div className={youtubeblock}>
        {/* <div className={youtubetitle}>
            <h2>１：テーマ選択、区域選択</h2>
        </div> */}
        <div className={youtube}>
            <iframe width="1120" height="315" src="https://www.youtube.com/embed/UK90VaTUY6U?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
        </div>
        <div className={youtube}>
            <iframe width="1120" height="315" src="https://www.youtube.com/embed/pVnIi8QIFNg?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
        </div>
        <div className={youtube}>
            <iframe width="1120" height="315" src="https://www.youtube.com/embed/DUyZG2oeuWE?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
        </div>
    </div>
    </div>


    <div className={kaisetsublock}>
        <div className={header2}>
            <h2 >1. ジオグラフの画面構成</h2>
        </div>
        <div className={kaisetsucont}>
            <div className={kaisetsu}>
                <p>ジオグラフでは、左側に凡例、右側に地域の状況を３次元グラフで「見える化」させた地図が配置されています。<br/>
                <br/>【トップページ】でタイトル部分をクリックし、【テーマ選択ページ】で「見える化」させたいテーマを選び終えると、こちらの【プレビューページ】が表示されます。<br/>
                <br/>【プレビューページ】では、まず、見たい地域の市町村名もしくは都道府県名を入力しましょう。３次元グラフの描画が始まります。<br/>
                なお、最初は、データ量が小さくなる面積の小さな市町村で、試しに入力してみると良いでしょう。
                </p>
            </div>
            <div className={simage}>
                <StaticImage src="../images/saas/tuto1.png" alt="img" />
            </div>
        </div>
    </div>

    <div className={kaisetsublock}>
        <div className={header2}>
            <h2>2. ジオグラフの凡例</h2>
        </div>
        <div className={kaisetsucont}>
            <div className={kaisetsu}>
                <p>
                3次元グラフが描画され始めたら、凡例を確認しましょう。こちらの例では、高さは人口、色は公共交通機関へのアクセスのしやすさになっていることがわかります。
                <br/><br/>凡例の詳細な説明は「詳細情報を確認」ボタンから確認できます。
                <br/>
                「公共交通利用圏」の定義の説明が確認でき、データの出典元へのリンクも備わっています。
                <br/><br/>
                最初のうちは、凡例と地図を交互に見ながら、読み取り方に慣れていきましょう。
                </p>
            </div>
            <div className={simage}>
                <StaticImage src="../images/saas/tuto6.png" alt="img" />
            </div>
        </div>
    </div>

    <div className={kaisetsublock}>
        <div className={header2}>
            <h2>3. ジオグラフの地図と３次元グラフ</h2>
        </div>
        <div className={kaisetsucont}>
            <div className={kaisetsu}>
                <p>
                地図と３次元グラフを確認しましょう。
                <br/>
                ジオグラフでは、地図上に人口や産業などのグラフが３次元で可視化されています。
                <br/><br/>
                プレビュー画面下のボタンで、以下の操作が可能です。
                <br/>
                ４ケタの数字：数字の年次の３次元グラフを表示します。<br/>
                視点を戻す：初期視点に戻ります。<br/>
                回転させる：中心を固定して回転します。<br/>
                全画面表示：プレビュー画面を全画面に拡大します。<br/>
                場所を確認する：カメラの直下の場所（視点が向いた先ではありません）をGoogle Mapで開きます。<Link to="/faq">※FAQもご参照ください。</Link><br/>
                背景だけを表示：３次元グラフを消します。<br/>
                GoogleEarthで表示：描画させた３次元グラフをKMLファイルとしてダウンロードできます。KMLファイルは、GoogleEarthPro上で呼び出すことができます。
                <br/><br/>
                まずは、「回転させる」を押してグラフを読み取り、「視点を戻す」を押して元の位置に戻ってみましょう。<br/>
                地図は、画面を直接タッチして自由に動かすこともできます。プレビュー画面内の右端のコントロールパネルでも操作できます。
                </p>
            </div>
            <div className={simage}>
                <StaticImage src="../images/saas/tuto3.png" alt="img" />
            </div>
        </div>
    </div>

    <div className={kaisetsublock}>
        <div className={header2}>
            <h2 >4. ジオグラフのモード</h2>
        </div>
        <div className={kaisetsucont}>
            <div className={kaisetsu}>
            <p>
            ジオグラフでは他にも、２つの地域を比較したり、国土全体で眺めたりもできる、５種類のモードを備えています。
        <br/>
            この「モード」は、上段のタブで変えられます。
        <br/>
            はじめは「自分で地域を選ぶ」モードとなっています。
        <br/>
            気になるタブを開いてみましょう。
        <br/>
            なお、「典型例とその解説」モードでは、選んだテーマについての解説も読むことができます。
        </p>
            </div>
            <div className={simage}>
                <StaticImage src="../images/saas/tuto4.png" alt="img" />
            </div>
        </div>
    </div>

    <div className={kaisetsublock}>
        <div className={header2}>
            <h2 >5. さあ、ジオグラフを使ってみよう</h2>
        </div>
        <div className={kaisetsucont}>
            <div className={kaisetsu}>

            <p>
            さあ、それではジオグラフを使って地域を探究していきましょう。<br/>
            以下は、代表的なテーマへのリンクとなります。<br/>
           </p>


    <table className={tablecss} style={{width:"90vw"}}>
	<thead>
		<tr>
			<th>テーマ</th>
			<th>高さ</th>
			<th>色</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>
        <Link to="/11-1/a2/#t">人口分布の変化</Link>
      </td>
			<td>人口総数</td>
			<td>人口密度</td>
		</tr>

		<tr>
			<td>
        <Link to="/11-5/a2/#t">シャッター通りと郊外型ショッピングセンター</Link>
      </td>
			<td>小売業年間販売額</td>
			<td>小売業年間販売額伸び率</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-3/a2/#t">コンパクトシティ</Link>
      </td>
			<td>人口総数</td>
			<td>公共交通利用圏</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-2/a2/#t">人口の都心回帰</Link>
      </td>
			<td>人口総数</td>
			<td>昼夜間人口比</td>
		</tr>

		<tr>
			<td>
      <Link to="/10-1/a2/#t">進む国際化</Link>
      </td>
			<td>外国人人口総数</td>
			<td>人口密度</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-4/a2/#t">鉄道・車と商業</Link>
      </td>
			<td>小売業年間販売額</td>
			<td>公共交通利用圏</td>
		</tr>

		<tr>
			<td>
      <Link to="/3-1/a2/#t">ニュータウンの高齢化</Link>
      </td>
			<td>老年人口率</td>
			<td>老年人口密度</td>
		</tr>

		<tr>
			<td>
      <Link to="/5-1/a2/#t">働く人々の男女比</Link>
      </td>
			<td>全産業従業者数</td>
			<td>全産業従業者数男女比</td>
		</tr>

	</tbody>
</table>


            </div>
        </div>
    </div>


</div>

    </Layout>
  );
};

export default App;